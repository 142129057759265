import React from 'react'
import { Link } from 'gatsby'
let logo = "https://cdn01.prd.pentcloud.com/site/Header/Logopentblack.svg"
const Footer = (props) => {
	return <div className="container mx-auto relative">
		<div className="flex lg:flex-row flex-col justify-between">
			<div className="flex-shrink-0 flex flex-row justify-start items-center lg:mr-24" style={{ width: '132px'}}>
				<img src={logo} alt="Pentcloud" className="hover:cursor-pointer" />
			</div>
			<div className="flex flex-row flex-wrap flex-auto justify-between items-center lg:mt-0 mt-12">
				{
					props.menu.map((menu, i) =>
						<Link to={menu.route} activeClassName="font-bold text-blue" className="mr-8 md:mr-0 last:mr-0" key={i} >
							<span className="hover:text-blue hover:font-bold transition duration-200 ease-in-out">
								{menu.label}
							</span>
						</Link>
					)
				}
			</div>
		</div>
		{/* <div className="flex flex-row mt-12 flex-wrap">
			<div className="pr-12 last:pr-0">
				<span className="text-sm text-blue-900 font-bold">
					Dribble
				</span>
			</div>
		</div> */}
	</div>
}

export default Footer