import React from "react"
import Header from '../components/Header'
import Footer from '../components/Footer'
import { Helmet } from "react-helmet"

let menu = [{
    label: 'Home',
    route: '/'
}, {
    label: 'Nosotros',
    route: '/nosotros'
}, {
    label: 'Servicios',
    route: '/servicios'
}, {
    label: 'Soluciones',
    route: '/soluciones'
// }, {
//     label: 'Clientes',
//     route: '/clientes'
// }, {
//     label: 'Blog',
//     route: '/blog'
}]

let phone = '+502 2214 3232'

export default function Layout({children}) {
    return (
        <div>
            <Helmet>
                <link href="/images/Iconbg2.svg" rel="shortcut icon" type="image/x-icon" />
            </Helmet>
            <header className="sticky top-0 bg-white z-10">
                <Header title="Pentcloud" {...{menu, phone}} />
            </header>
            <main className="container main pt-12">
                {children}
            </main>
            <footer className="bg-gray-100 py-12 px-24">
                <Footer {...{menu}} />
            </footer>
        </div>
    )
}
