import React, {useState} from 'react'
import { Link } from 'gatsby'
import AniLink from "gatsby-plugin-transition-link/AniLink";
import Button from '../components/Button'

let logo = "//cdn01.prd.pentcloud.com/site/Header/Logopent.svg"

// let showResponsiveMenu = false

const scrollTo = (el) => {
	document.getElementById(el).scrollIntoView({behavior: 'smooth', block: 'center', inline: 'start'})
}

const Header = (props) => {
	// console.log(props.location)
	const [showResponsiveMenu, setMenu] = useState(false);
	const showMenu = () => {
		setMenu(!showResponsiveMenu)
		console.log(showResponsiveMenu)
	}
	return (
<div className="container mx-auto px-12 py-8 relative">
			<div className="grid lg:grid-cols-12 gap-4 grid-cols-2 ">
		<div className="lg:col-span-2 col-span-1 flex flex-row justify-start items-center">
			{/* <Link to="/" activeClassName="font-bold text-blue"> */}
			<AniLink paintDrip hex="#FFFFFF" to="/" activeClassName="font-bold text-blue">
						<img src={logo} width="160px" alt="Pentcloud" className="hover:cursor-pointer" />
					</AniLink>
					{/* </Link> */}
				</div>
				<div className="lg:col-span-6 lg:flex flex-row justify-evenly items-center hidden">
					{
						props.menu.map((menu, i) =>
							// <Link to={menu.route} activeClassName="font-bold text-blue" key={i} >
							// 	<span className="hover:text-blue hover:font-bold transition duration-200 ease-in-out">
							// 		{menu.label}
							// 	</span>
							// </Link>
							<AniLink paintDrip hex="#FFFFFF" to={menu.route} activeClassName="font-bold text-blue" key={i}>
								<span className="hover:text-blue hover:font-bold transition duration-200 ease-in-out">
									{menu.label}
								</span>
							</AniLink>
						)
					}
				</div>
				<div className="col-span-4 lg:flex flex-row justify-end items-center content-center text-blue hover:font-bold hover:cursor-pointer transition duration-200 ease-in-out hidden">
					{/* <img src="//pentcloud.com/images/PhoneIcon.svg" width="15px" alt="Pentcloud" /> &nbsp; {props.phone} */}
					<Link to='//wa.link/pentcloud'>
						<Button classes="flex flex-row space-between items-center px-4 py-2 bg-green-900 text-white rounded-lg text-sm mr-4 border-2 border-green-900" >
							<img src="//pentcloud.com/images/WhatsAppWhite.svg" width="16px" alt="Pentcloud" /> &nbsp; Envía un mensaje
						</Button>
					</Link>
					<AniLink paintDrip hex="#FFFFFF" to="/#mainForm">
						<Button classes="flex flex-row space-between items-center px-4 py-2 text-green-900 bg-white rounded-lg text-sm border-2 border-green-900">
						<img src="//pentcloud.com/images/Email.svg" width="16px" alt="Pentcloud" /> &nbsp; Escríbe al correo
						</Button>
					</AniLink>
				</div>
				<div className="lg:hidden flex col-span-1 flex-row justify-end items-center content-center transition duration-200 ease-in-out">
					<img src="//pentcloud.com/images/Frame.svg" width="33px" alt="Menú" className="hover:cursor-pointer" onClick={showMenu} />
				</div>
			</div>
			<div className={`justify-center items-center lg:hidden flex flex-col mt-8 overflow-hidden ${showResponsiveMenu ? 'h-content' : 'h-0'}`}>
					{
						props.menu.map((menu, i) =>
							<AniLink paintDrip hex="#FFFFFF" to={menu.route} activeClassName="font-bold text-blue" className="mt-4" key={i}>
								<span className="hover:text-blue hover:font-bold transition duration-200 ease-in-out">
									{menu.label}
								</span>
							</AniLink>
						)
					}
					<div className={`flex flex-row items-center mt-8`}>
						<Link to='//wa.link/pentcloud'>
							<Button classes="flex flex-row space-between items-center px-4 py-2 bg-green-900 text-white rounded-lg text-sm mr-4 border-2 border-green-900" >
								<img src="//pentcloud.com/images/WhatsAppWhite.svg" width="16px" alt="Pentcloud" /> &nbsp; Envía un mensaje
							</Button>
						</Link>
						<AniLink paintDrip hex="#FFFFFF" to="/#mainForm">
							<Button classes="flex flex-row space-between items-center px-4 py-2 text-green-900 bg-white rounded-lg text-sm border-2 border-green-900" >
								<img src="//pentcloud.com/images/Email.svg" width="16px" alt="Pentcloud" /> &nbsp; Escríbe al correo
							</Button>
						</AniLink>
					</div>
				</div>
		</div>
	)
}

export default Header
